var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("q-form", { ref: "editForm" }, [
    _c(
      "div",
      { staticClass: "row" },
      [
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3" },
          [
            _c(
              "c-card",
              {
                staticClass: "cardClassDetailForm",
                attrs: { title: "작업 정보" },
              },
              [
                _c(
                  "template",
                  { slot: "card-button" },
                  [
                    _c(
                      "q-btn-group",
                      { attrs: { outline: "" } },
                      [
                        _vm.editable
                          ? _c("c-btn", {
                              attrs: {
                                url: _vm.saveUrl,
                                isSubmit: _vm.isSave,
                                param: _vm.data,
                                mappingType: _vm.mappingType,
                                label: "일괄 저장",
                                icon: "save",
                              },
                              on: {
                                beforeAction: _vm.saveInfo,
                                btnCallback: _vm.saveCallback,
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c("template", { slot: "card-detail" }, [
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      { staticClass: "col-12" },
                      [
                        _c("c-plant", {
                          attrs: {
                            editable: _vm.editable,
                            required: true,
                            type: "edit",
                            name: "plantCd",
                          },
                          model: {
                            value: _vm.data.plantCd,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "plantCd", $$v)
                            },
                            expression: "data.plantCd",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-12" },
                      [
                        _c("c-text", {
                          attrs: {
                            required: true,
                            editable: _vm.editable,
                            label: "작업명",
                            name: "workName",
                          },
                          model: {
                            value: _vm.data.workName,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "workName", $$v)
                            },
                            expression: "data.workName",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-6" },
                      [
                        _c("c-datepicker", {
                          attrs: {
                            required: true,
                            editable: _vm.editable,
                            label: "작업기간-시작일",
                            name: "workStartDt",
                          },
                          model: {
                            value: _vm.data.workStartDt,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "workStartDt", $$v)
                            },
                            expression: "data.workStartDt",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-6" },
                      [
                        _c("c-datepicker", {
                          attrs: {
                            required: true,
                            editable: _vm.editable,
                            label: "작업기간-종료일",
                            name: "workEndDt",
                          },
                          model: {
                            value: _vm.data.workEndDt,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "workEndDt", $$v)
                            },
                            expression: "data.workEndDt",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-12" },
                      [
                        _c("c-vendor", {
                          attrs: {
                            editable: _vm.editable,
                            plantCd: _vm.data.plantCd,
                            label: "작업 업체",
                            name: "vendorCd",
                          },
                          model: {
                            value: _vm.data.vendorCd,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "vendorCd", $$v)
                            },
                            expression: "data.vendorCd",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-12" },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable,
                            label: " 작업 인원",
                            name: "workers",
                            type: "number",
                            suffix: "명",
                            maxlength: 3,
                          },
                          model: {
                            value: _vm.data.workers,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "workers", $$v)
                            },
                            expression: "data.workers",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-6" },
                      [
                        _c("c-checkbox", {
                          attrs: {
                            editable: _vm.editable,
                            isFlag: true,
                            label: "위험작업여부",
                            name: "dangerFlag",
                          },
                          model: {
                            value: _vm.data.dangerFlag,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "dangerFlag", $$v)
                            },
                            expression: "data.dangerFlag",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-6" },
                      [
                        _c("c-checkbox", {
                          attrs: {
                            editable: _vm.editable,
                            isFlag: true,
                            label: "사용여부",
                            name: "useFlag",
                          },
                          model: {
                            value: _vm.data.useFlag,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "useFlag", $$v)
                            },
                            expression: "data.useFlag",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
              ],
              2
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-12 col-md-9 col-lg-9 col-xl-9" },
          [
            _c(
              "c-card",
              {
                staticClass: "cardClassDetailForm",
                attrs: {
                  title: "작업 위치",
                  noMarginPadding: true,
                  height: _vm.imgHeight,
                },
              },
              [
                _c(
                  "template",
                  { slot: "card-button" },
                  [
                    _c(
                      "q-btn-group",
                      { attrs: { outline: "" } },
                      [
                        _vm.editable
                          ? _c("c-btn", {
                              attrs: { label: "지도 변경", icon: "place" },
                              on: { btnClicked: _vm.selectMap },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c("template", { slot: "card-detail" }, [
                  _c("div", { staticClass: "col-12" }, [
                    _c("div", { ref: "parent", attrs: { id: "parent" } }, [
                      _vm.setComplete
                        ? _c(
                            "div",
                            { style: { height: _vm.imgHeight } },
                            [
                              _c(
                                "VueDraggableResizable",
                                {
                                  ref: "markImage",
                                  staticClass: "my-class",
                                  attrs: {
                                    parent: true,
                                    draggable: _vm.editable,
                                    resizable: _vm.editable,
                                    "class-name-dragging": "my-dragging-class",
                                    x: _vm.data.locationX,
                                    y: _vm.data.locationY,
                                    w: _vm.data.locationW,
                                    h: _vm.data.locationH,
                                    grid: [10, 10],
                                    minHeight: 20,
                                    minWidth: 20,
                                  },
                                  on: {
                                    dragging: (x, y) =>
                                      _vm.onDrag(_vm.data, x, y),
                                    resizing: (x, y, w, h) =>
                                      _vm.onResize(_vm.data, x, y, w, h),
                                  },
                                },
                                [
                                  _c("div", { staticClass: "loc-header" }, [
                                    _vm._v(_vm._s(_vm.data.workName)),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]),
                  ]),
                ]),
              ],
              2
            ),
          ],
          1
        ),
        _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }