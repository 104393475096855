<template>
  <q-form ref="editForm">
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
        <c-card title="작업 정보" class="cardClassDetailForm">
          <template slot="card-button">
            <q-btn-group outline >
              <c-btn
                v-if="editable"
                :url="saveUrl"
                :isSubmit="isSave"
                :param="data"
                :mappingType="mappingType"
                label="일괄 저장"
                icon="save"
                @beforeAction="saveInfo"
                @btnCallback="saveCallback"
              />
            </q-btn-group>
          </template>
          <template slot="card-detail">
            <div class="row">
              <div class="col-12">
                <c-plant
                  :editable="editable"
                  :required="true"
                  type="edit" 
                  name="plantCd" 
                  v-model="data.plantCd" />
              </div>
              <div class="col-12">
                <c-text
                  :required="true"
                  :editable="editable"
                  label="작업명"
                  name="workName"
                  v-model="data.workName">
                </c-text>
              </div>
              <div class="col-6">
                <c-datepicker
                  :required="true"
                  :editable="editable"
                  label="작업기간-시작일"
                  name="workStartDt"
                  v-model="data.workStartDt"
                />
              </div>
              <div class="col-6">
                <c-datepicker
                  :required="true"
                  :editable="editable"
                  label="작업기간-종료일"
                  name="workEndDt"
                  v-model="data.workEndDt"
                />
              </div>
              <div class="col-12">
                <c-vendor
                  :editable="editable"
                  :plantCd="data.plantCd"
                  label="작업 업체"
                  name="vendorCd"
                  v-model="data.vendorCd" />
              </div>
              <div class="col-12">
                <c-text
                  :editable="editable"
                  label=" 작업 인원"
                  name="workers"
                  type="number"
                  suffix="명"
                  :maxlength="3"
                  v-model="data.workers">
                </c-text>
              </div>
              <div class="col-6">
                <c-checkbox
                  :editable="editable"
                  :isFlag="true"
                  label="위험작업여부"
                  name="dangerFlag"
                  v-model="data.dangerFlag"
                />
              </div>
              <div class="col-6">
                <c-checkbox
                  :editable="editable"
                  :isFlag="true"
                  label="사용여부"
                  name="useFlag"
                  v-model="data.useFlag"
                />
              </div>
            </div>
          </template>
        </c-card>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-9 col-lg-9 col-xl-9">
        <c-card title="작업 위치" class="cardClassDetailForm" :noMarginPadding="true" :height="imgHeight"> 
          <template slot="card-button">
            <q-btn-group outline >
              <c-btn v-if="editable" label="지도 변경" icon="place" @btnClicked="selectMap" />
            </q-btn-group>
          </template>
          <template slot="card-detail">
            <div class="col-12">
              <div id="parent" ref="parent">
                <div v-if="setComplete" :style="{'height': imgHeight}">
                  <VueDraggableResizable
                    ref="markImage"
                    class="my-class"
                    :parent="true"
                    :draggable="editable"
                    :resizable="editable"
                    class-name-dragging="my-dragging-class"
                    :x="data.locationX"
                    :y="data.locationY"
                    :w="data.locationW" 
                    :h="data.locationH"
                    :grid="[10, 10]"
                    :minHeight="20" :minWidth="20"
                    @dragging="(x, y) => onDrag(data, x, y)"
                    @resizing="(x, y, w, h) => onResize(data, x, y, w, h)"
                  >
                  <div class="loc-header">{{data.workName}}</div>
                  </VueDraggableResizable>
                </div>
              </div>
            </div>
          </template>
        </c-card>
      </div>
      <c-dialog :param="popupOptions"></c-dialog>
    </div>
  </q-form>
</template>

<script>
import VueDraggableResizable from 'vue-draggable-resizable'
import 'vue-draggable-resizable/dist/VueDraggableResizable.css'
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: "work-location-detail",
  components: { VueDraggableResizable },
  props: {
    popupParam: {
      type: Object,
      default: function() {
        return {
          sopWorkLocationId: '',
          plantCd: null,
        }
      },
    },
    contentHeight: {
      type: [String, Number],
      default: function() {
        return 'auto'
      },
    }
  },
  data() {
    return {
      setComplete: false,
      mapSrc: require('@/assets/images/no-image.png'),
      imgHeight: '',
      editable: false,
      listUrl: '',
      mappingType: 'POST',
      isSave: false,
      infoUrl: '',
      saveUrl: '',
      data: {
        sopWorkLocationId: '',  // 작업현황 일련번호
        companyCd: '',  // 회사코드
        plantCd: null,  // 공사현장 코드
        workName: '',  // 작업명
        workStartDt: '',  // 작업기간-시작일
        workEndDt: '',  // 작업기간-종료일
        vendorCd: '',  // 작업 업체코드
        workers: 0,  // 작업 인원
        dangerFlag: 'N',  // 위험작업여부
        sopMapId: '',  // 지도 일련번호
        locationX: 0,  // 작업위치 X좌표
        locationY: 0,  // 작업위치 Y좌표
        locationW: 100,  // 작업위치 넓이
        locationH: 100,  // 작업위치 높이
        useFlag: 'Y',  // 사용여부
      },
      ratio: null,
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '90%',
        top: '10px',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  watch: {
    contentHeight() {
      this.imgHeight = String(this.contentHeight - 80) + 'px'
    }
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {},
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.listUrl = selectConfig.sop.swp.map.recent.url
      this.data.companyCd = this.$store.getters.user.companyCd  // 회사코드
      this.data.plantCd = this.popupParam.plantCd
      this.saveUrl = transactionConfig.sop.work.loc.insert.url
      this.infoUrl = selectConfig.sop.work.loc.get.url
      if (this.popupParam.sopWorkLocationId) {
        this.getInfo();
      } else {
        this.getMap();
      }
    },
    getInfo() {
      this.$http.url = this.infoUrl;
      this.$http.type = 'GET';
      this.$http.param = {sopWorkLocationId: this.popupParam.sopWorkLocationId}
      this.$http.request((_result) => {
        this.data = _result.data;
        this.$comm.previewImageParamTask(
          _result.data.sopMapId,
          'WORK_MAP'
        ).then(_result => {
          this.mapSrc = _result;
          this.setCanvas();
        });
      },);
    },
    getMap() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        useFlag: 'Y',
        plantCd: this.popupParam.plantCd
      };
      this.$http.request((_result) => {
        if (_result.data) {
          let sopmapid = _result.data.sopMapId;
          this.$comm.previewImageParamTask(
            _result.data.sopMapId,
            'WORK_MAP'
          ).then(_result => {
            this.mapSrc = _result;
            this.data.sopMapId = sopmapid;
            this.setCanvas();
          });
        } else {
          window.getApp.$emit('ALERT', {
            title: '안내', // 안내
            message: '공사현장별 지도정보를 등록 후 이용하시기 바랍니다.', // 지도정보를 등록 후 이용하시기 바랍니다.
            type: 'warning', // success / info / warning / error
          });
        }
      },);
    },
    setCanvas() {
      let image = new Image();
      image.src = this.mapSrc;
      let thisVue = this;
      image.onload = function () {
        let parent = document.getElementById('parent');
        thisVue.ratio = image.width / image.height
        let _width = thisVue.ratio * 700

        parent.style.cssText  = 'background: linear-gradient(-90deg, rgba(0, 0, 0, 0.1) 1px, transparent 1px) 0% 0% / ' 
          + 10 + 'px ' + 10 + 'px, linear-gradient(rgba(0, 0, 0, 0.1) 1px, transparent 1px) 0% 0% / ' + 10 + 'px ' + 10 + 'px '
          + ', url("' + thisVue.mapSrc + '") no-repeat left/contain;'
          + 'overflow:auto; transform-origin: left top;'
          // + 'background-size: 800px;'
          + 'height:700px;width:' + _width + ';';
          // + 'height:100%;width:800px;';
          // + 'height:800px;width:800px';

        setTimeout(() => {
          thisVue.setComplete = true;
        }, 300);
      };
    },
    selectMap() {
      this.popupOptions.title = '지도 검색'; // 지도 검색
      this.popupOptions.param = {
        plantCd: this.popupParam.plantCd,
      };
      this.popupOptions.target = () => import(`${'@/pages/sop/swp/base/workMapPop.vue'}`);
      this.popupOptions.width = '70%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeMapPopup;
    },
    closeMapPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.ratio = data[0].ratio
        this.$comm.previewImage({
          sysAttachFileId: data[0].sysAttachFileId,
          contentType: data[0].contentType,
        }).then(_result => {
          this.mapSrc = _result;
          this.setCanvas();
        });

        this.data.sopMapId = data[0].sopMapId;
      }
    },
    onDrag(item, x, y) {
      this.$set(item, 'locationX', x)
      this.$set(item, 'locationY', y)
      this.$set(item, 'chgUserId', this.$store.getters.user.userId)
    },
    onResize(item, x, y, w, h) {
      this.$set(item, 'locationX', x)
      this.$set(item, 'locationY', y)
      this.$set(item, 'locationW', w)
      this.$set(item, 'locationH', h)
      this.$set(item, 'chgUserId', this.$store.getters.user.userId)
    },
    saveInfo() {
      if (this.popupParam.sopWorkLocationId) {
        this.saveUrl = transactionConfig.sop.work.loc.insert.url;
        this.mappingType = 'PUT';
      } else {
        this.saveUrl = transactionConfig.sop.work.loc.update.url;
        this.mappingType = 'POST';
      }
      this.$refs['editForm'].validate().then((_result) => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '저장하시겠습니까?',
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.data.regUserId = this.$store.getters.user.userId;
              this.data.chgUserId = this.$store.getters.user.userId;
              this.isSave = !this.isSave;
            },
            // 취소 callback 함수
            cancelCallback: () => {},
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveCallback(_result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.popupParam.sopWorkLocationId = _result.data.sopWorkLocationId;
      console.log('_result',_result.data.sopWorkLocationId)
      this.getInfo();
    },
  },
};
</script>
<style>
.my-class {
  /* background-color: #9E9E9E; */
  border: 3px dashed #FF5722;
  -webkit-transition: background-color 200ms linear;
  -ms-transition: background-color 200ms linear;
  transition: background-color 200ms linear;
  position: absolute;
  text-align: center;
}

.my-dragging-class {
  background-color: #FF5722;
  border: 3px dashed #FF5722;
}
.loc-header {
  width: 100%;
  background: #fff;
  font-weight: 700;
}
</style>